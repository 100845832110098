import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit'

type SidePanelState = {
  isCollapsed: boolean;
  activeVehicleId: number | null;
}

const initialState: SidePanelState = {
  isCollapsed: localStorage.getItem('sidePanelCollapsed') === 'true',
  activeVehicleId: null,
};

const sidePanelSlice = createSlice({
  name: 'auctions',
  initialState,
  reducers: {
      setActiveVehicleId: (state, action: PayloadAction<number | null>) => { 
        state.activeVehicleId = action.payload;
      },
      toggleCollapsed: (state) => {
        state.isCollapsed = !state.isCollapsed;
        localStorage.setItem('sidePanelCollapsed', state.isCollapsed.toString());
      }
    }
});

export const { setActiveVehicleId, toggleCollapsed } = sidePanelSlice.actions
export default sidePanelSlice;
